<article class="flex align-items-center">
  <img src="assets/logos/cvs-logo.svg" alt="Retailer logo" class="mr-2" />

  <div class="retailer">
    <p *twxIsNotMobile class="m-0 text-xs">CVS</p>
    <p class="m-0 line-height-1 font-semibold store">
      <span *twxIsNotMobile>Store </span>3112
    </p>
  </div>
</article>
