import { inject, Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { LOCAL_STORAGE_USER_KEY } from "../../utils/app.constants.utils";
import { LoginForm, User } from "../utils/user.models";
import { UserRole, UserType } from "../utils/user.enums";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  readonly user = new BehaviorSubject<User | null>(
    localStorage.getItem(LOCAL_STORAGE_USER_KEY)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY) as string)
      : null,
  );

  private router = inject(Router);
  private http = inject(HttpClient);

  get user$() {
    return this.user.asObservable();
  }

  isAuthenticated(): boolean | UrlTree {
    return !!this.user.value || this.router.parseUrl("/auth/login");
  }

  login(form: LoginForm): void {
    const url = environment.api + "/v1/auth/login";

    this.http.post<User>(url, form).subscribe();

    const mock = {
      id: "1",
      email: form.email,
      type: UserType.SYSTEM,
      role: UserRole.SUPER_ADMIN,
      createdOn: new Date().toISOString(),
    };
    this.user.next(mock);
    localStorage.setItem(
      LOCAL_STORAGE_USER_KEY,
      JSON.stringify(this.user.value),
    );
    this.router.navigate(["/"]);
  }

  signOut(): void {
    this.user.next(null);
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    this.router.navigate(["/auth/login"]);
  }
}
