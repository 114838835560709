import {
  DestroyRef,
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { LayoutService } from "../../layout/service/app.layout.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { tap } from "rxjs";

@Directive({
  selector: "[twxIsNotMobile]",
  standalone: true,
})
export class IsNotMobileDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private layout: LayoutService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit() {
    this.layout.isViewportMobile$
      .pipe(
        tap((isMobile) => {
          if (!isMobile) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
