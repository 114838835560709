import { Component } from "@angular/core";
import { IsNotMobileDirective } from "../../directives/is-not-mobile.directive";

@Component({
  selector: "twx-retailer-sidebar-card",
  standalone: true,
  imports: [IsNotMobileDirective],
  templateUrl: "./retailer-sidebar-card.component.html",
  styleUrl: "./retailer-sidebar-card.component.scss",
})
export class RetailerSidebarCardComponent {}
