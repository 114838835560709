import { Component } from "@angular/core";
import { routes } from "../../environments/environment.routes";

@Component({
  selector: "twx-bottombar",
  templateUrl: "./app.bottombar.component.html",
  styleUrl: "./app.bottombar.component.scss",
})
export class BottombarComponent {
  readonly alertsRoute = routes.frontend.alerts;
}
