<div class="bottom-bar">
  <ul
    class="bottom-bar-list flex align-items-center justify-content-around p-0 m-0"
  >
    <li>
      <a
        [routerLink]="alertsRoute"
        routerLinkActive="active"
        class="flex flex-column align-items-center justify-content-center"
      >
        <i class="pi pi-receipt"></i>
        <small class="font-semibold">Alerts</small>
      </a>
    </li>
    <li>
      <div class="new-item flex align-items-center justify-content-center">
        <i class="pi pi-plus font-bold"></i>
      </div>
    </li>
    <li>
      <a class="flex flex-column align-items-center justify-content-center">
        <i class="pi pi-users"></i>
        <small class="font-semibold">Contacts</small>
      </a>
    </li>
  </ul>
</div>
