export const LOCAL_STORAGE_USER_KEY = "twx-user";

export const TACTIQ_DEFAULT_THEME = {
  mainBgColor: "#FFFFFF",
  mainTextColor: "#343434",
  mainPrimaryColor: "#1d5163",
  mainLogoContrastUrl: "assets/logos/horiz-globalworx-white.svg",

  loginBgColor: "#FFFFFF",
  loginBgImgUrl: "assets/logos/tactiq-hero.svg",
  loginMainLogoUrl: "assets/logos/horiz-globalworx.svg",
  loginFooterLogoUrl: "assets/logos/tactiq-logo.svg",
  loginFooterTemplate: `
      <p class="text-sm">
        By logging in to your account, you agree with our
        <a
          style="color: var(--primary-color);font-weight: 700;"
          href="https://www.tactiqtech.com/privacy-policy-terms/"
          target="_blank"
          >Terms of Conditions and Privacy Policy</a
        >
      </p>
      <p class="text-sm">
        Need assistance?
        <a style="color: var(--primary-color);font-weight: 700;" href="mailto:acuitysupport@tactiqtech.com"
          >Contact Us</a
        >
      </p>
    `,

  resetPasswordFooterLogoUrl: "assets/logos/Tactiq-Globalworx-Lock-up.svg",
  resetPasswordFooterTemplate: `
      <p class="text-sm">
        Need assistance?
        <a style="color: var(--primary-color);font-weight: 700;" href="mailto:acuitysupport@tactiqtech.com"
          >Contact Us</a
        >
      </p>
  `,

  sidebarColor: "#053740",
};
